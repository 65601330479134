import * as React from "react"
import { Link, graphql } from "gatsby"
import { Helmet } from "react-helmet"

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"

import '../styles/Blog.css'

import label_dark from '../images/icons/label-dark.svg'


const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <Seo title="All posts" />
        <Bio />
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    )
  }

  return (
    <>
      <Layout location={location} title={siteTitle}>
        <Helmet>
          <meta property="og:image" content="https://i.imgur.com/4TkxwI8.png" />
          <meta name="twitter:image" content="https://i.imgur.com/4TkxwI8.png" />
        </Helmet>
        <Seo 
          title="All posts"
          description="What is JL Caraveo writting on?"
       />
        <main className="blog--container">
          <section className="blog-hero--container">
            <article className="blog-hero--content">
                <div className="blog-intro--container">
                    <h2 className='blog-intro'>Welcome to JL Caraveo Blog!</h2>
                </div>
                <p className='blog-description'>In this Blog you will be able to find from general topics that I like to topics related to Machine Learning and Deep Learning. Enjoy it!</p>
            </article>
          </section>
          <section className="blog-content">
            <div className="all-blogs">
              {posts.map(post => {
                if (post.frontmatter.type === 'post-blog') {
                  return (
                    <Link to={post.fields.slug} itemProp="url">
                      <section key={post.fields.slug} className="blogs--container">
                        <div className="blogItem--container">
                          <section className="image--container">
                            <img src={post.frontmatter.image_url} alt="BlogImage" />
                          </section>
                          <section className="blogItem--labels">
                            {
                              post.frontmatter.labels.split(',').map((label) => (
                                <div className="blogItem--label">
                                  <div className="label-img--container">
                                    <img src={label_dark} alt="Label-icon" />
                                  </div>
                                  <p>{label}</p>
                                </div>
                              ))
                            }
                          </section>
                          <section>
                            <article className="blogItem--content">
                              <h3>{post.frontmatter.title}</h3>
                              <p>{post.frontmatter.description}</p>
                              <section className="blog-author--container">
                                  <article className="perfil-img--container">
                                      <img src="https://imgur.com/7NSDJLv.jpg" alt="perfilImage" />    
                                  </article>
                                  <article className="writter-blog-container">
                                      <p className='author'><strong>{post.frontmatter.author}</strong></p>
                                      <p className='date'>{post.frontmatter.date}</p>
                                  </article>
                              </section>
                            </article>
                          </section>
                        </div>
                      </section>
                    </Link>
                  )
                }
              })}
            </div>
          </section>
        </main>
      </Layout>
    </>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          author
          image_url
          type
          labels
        }
      }
    }
  }
`
